export const ellipseIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="20"
      height="4"
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="2"
        r="2"
        transform="rotate(-180 18 2)"
        fill={color ?? "white"}
      />
      <circle
        cx="10"
        cy="2"
        r="2"
        transform="rotate(-180 10 2)"
        fill={color ?? "white"}
      />
      <circle
        cx="2"
        cy="2"
        r="2"
        transform="rotate(-180 2 2)"
        fill={color ?? "white"}
      />
    </svg>
  );
};
