import { useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { AuthState } from "./AuthState";

export function useAuth(): AuthState {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error("useAuth must be called within an <AuthProvider>");
  }
  return context;
}
