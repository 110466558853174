import { useQuery } from "@apollo/client";
import { MyIntermediaryDeviceStatsDocument } from "generated/graphql/graphql";
import styled from "styled-components";
import { Loader } from "../../components";
import { SummaryBox } from "./SummaryBox";

interface Props {
  activeIntermediaries: string[];
}

export function Summary(props: Props): JSX.Element {
  const { activeIntermediaries } = props;

  const { data: meDataStats, loading: isLoadingStats } = useQuery(
    MyIntermediaryDeviceStatsDocument
  );

  if (isLoadingStats) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (!meDataStats || !meDataStats.me.intermediaries) {
    return (
      <Container>
        <>Fant ingen statistikk 🤷‍♂️</>
      </Container>
    );
  }

  let total = 0;
  let returnedDevices = 0;
  let devicesAtCustomer = 0;
  let activeDevices = 0;
  let inactiveDevices = 0;

  for (const intermediary of meDataStats.me.intermediaries) {
    if (intermediary.deviceStats == null) {
      continue;
    }

    if (
      !activeIntermediaries.includes(intermediary.id) &&
      activeIntermediaries.length !== 0
    ) {
      continue;
    }

    total += intermediary.deviceStats.total;
    returnedDevices += intermediary.deviceStats.returnedDevices;
    devicesAtCustomer += intermediary.deviceStats.devicesAtCustomer;
    activeDevices +=
      intermediary.deviceStats.devicesActivated +
      intermediary.deviceStats.devicesActivationReady;
    inactiveDevices += intermediary.deviceStats.devicesDeactivated;
  }

  return (
    <Container>
      <DeviceSummary>
        <SummaryBox title="Enheter" value={`${total}`} percentage={100} />

        <SummaryBox
          title="Aktivert"
          value={`${activeDevices}/${total}`}
          percentage={(activeDevices / total) * 100}
        />

        <SummaryBox
          title="Deaktivert"
          value={`${inactiveDevices}/${total}`}
          percentage={(inactiveDevices / total) * 100}
        />
        <SummaryBox
          title="Hos kunde"
          value={`${devicesAtCustomer}/${total}`}
          percentage={(devicesAtCustomer / total) * 100}
        />

        <SummaryBox
          title="Returnert"
          value={`${returnedDevices}/${total}`}
          percentage={(returnedDevices / total) * 100}
        />
      </DeviceSummary>
    </Container>
  );
}

const DeviceSummary = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  width: 80%;
  color: ${({ theme }): string => theme.colors.blue[500]};

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
`;
