import { ServerError } from "domain/ServerError";

export interface SignInParams {
  email: string;
  password: string;
}

export interface RegisterParams {
  email: string;
  password: string;
}

export interface ResetParams {
  email: string;
}

export interface User {
  id: string;
  email: string | null;
}

export interface AuthState {
  isFetchingUser: boolean;
  emailInput: string;
  setEmailInput: (email: string) => void;
  signIn: ({ email, password }: SignInParams) => Promise<void>;
  signOut: () => void;
  register: ({ email, password }: RegisterParams) => Promise<void>;
  resetPassword: ({ email }: ResetParams) => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
  isResendingVerificationEmail: boolean;

  signInState: SignInState;
  registerState: RegisterState;
  resetPasswordState: ResetPasswordState;

  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;

  clearLoginState: () => void;
}

export enum RegisterStatus {
  INITIAL,
  REGISTERING,
  FAILED,
  VERIFICATION_MESSAGE_VISIBLE,
  SUCCESS,
}
interface AwaitingRegistration {
  status: RegisterStatus.INITIAL;
}

interface Registering {
  status: RegisterStatus.REGISTERING;
}

interface RegistrationFailed {
  status: RegisterStatus.FAILED;
  error: ServerError;
}

interface ShowRegistrationVerificationMessage {
  status: RegisterStatus.VERIFICATION_MESSAGE_VISIBLE;
}

interface RegistrationSuccessful {
  status: RegisterStatus.SUCCESS;
}

export type RegisterState =
  | AwaitingRegistration
  | Registering
  | RegistrationFailed
  | ShowRegistrationVerificationMessage
  | RegistrationSuccessful;

export enum ResetPasswordStatus {
  INITIAL,
  RESETTING,
  FAILED,
  RESET_MESSAGE_VISIBLE,
  SUCCESS,
}

interface AwaitingResetPassword {
  status: ResetPasswordStatus.INITIAL;
}

interface ResettingPassword {
  status: ResetPasswordStatus.RESETTING;
}

interface ResetPasswordFailed {
  status: ResetPasswordStatus.FAILED;
  error: ServerError;
}

interface ShowResetPasswordResetMessage {
  status: ResetPasswordStatus.RESET_MESSAGE_VISIBLE;
}

interface ResetPasswordSuccessful {
  status: ResetPasswordStatus.SUCCESS;
}

export type ResetPasswordState =
  | AwaitingResetPassword
  | ResettingPassword
  | ResetPasswordFailed
  | ShowResetPasswordResetMessage
  | ResetPasswordSuccessful;

export enum SignInStatus {
  INITIAL,
  SIGNING_IN,
  FAILED,
  EMAIL_NOT_VERIFIED,
  SUCCESS,
}

interface AwaitingSignIn {
  status: SignInStatus.INITIAL;
}

interface SigningIn {
  status: SignInStatus.SIGNING_IN;
}

interface SignInSuccessful {
  status: SignInStatus.SUCCESS;
}

interface EmailNotVerified {
  status: SignInStatus.EMAIL_NOT_VERIFIED;
}

interface SignInFailed {
  status: SignInStatus.FAILED;
  error: ServerError;
}

export type SignInState =
  | AwaitingSignIn
  | SigningIn
  | SignInSuccessful
  | EmailNotVerified
  | SignInFailed;
