import { ActivationStatus } from "generated/graphql/graphql";

export enum TranslatedActivationStatus {
  ACTIVATED = "Aktivert",
  DEACTIVATED = "Deaktivert",
  UNKNOWN = "Ukjent",
}

export const getTranslatedActivationStatus = (
  status: ActivationStatus | undefined | null
): TranslatedActivationStatus => {
  switch (status) {
    case ActivationStatus.Activated:
      return TranslatedActivationStatus.ACTIVATED;
    case ActivationStatus.Deactivated:
      return TranslatedActivationStatus.DEACTIVATED;
    default:
      return TranslatedActivationStatus.UNKNOWN;
  }
};
