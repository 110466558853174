export const capitalize = (str: string | undefined, all?: boolean) => {
  if (!str) return "";
  if (all) {
    const tempArray = str.split(" ");
    const temp = tempArray.map(
      (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    );
    return temp.join(" ");
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
