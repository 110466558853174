export const exclamationIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.5 13H6.5V16H9.5V13Z" fill={color ?? "#FFA0B7"} />
      <path d="M9.5 0H6.5V10H9.5V0Z" fill={color ?? "#FFA0B7"} />
    </svg>
  );
};
