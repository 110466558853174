export const checkIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.942809"
        y1="7.89053"
        x2="6.27614"
        y2="13.2239"
        stroke={color ?? "white"}
        strokeWidth="2.66667"
      />
      <line
        x1="4.3905"
        y1="13.2238"
        x2="15.0572"
        y2="2.55718"
        stroke={color ?? "white"}
        strokeWidth="2.66667"
      />
    </svg>
  );
};
