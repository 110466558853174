import React from "react";
import styled from "styled-components";

export enum LoaderSize {
  SMALL = "SMALL",
  NORMAL = "NORMAL",
  LARGE = "LARGE",
}

interface Props {
  size?: LoaderSize;
}

export function Loader({ size }: Props): JSX.Element {
  return (
    <Container>
      <Spinner size={size} />
    </Container>
  );
}

const Container = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const Spinner = styled.span<Props>`
  width: ${(props) =>
    props.size === LoaderSize.SMALL
      ? "20px"
      : props.size === LoaderSize.LARGE
      ? "80px"
      : "40px"};
  height: ${(props) =>
    props.size === LoaderSize.SMALL
      ? "20px"
      : props.size === LoaderSize.LARGE
      ? "80px"
      : "40px"};

  border: 4px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
