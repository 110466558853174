import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "@fontsource/source-sans-pro";
import { ACCESS_TOKEN_STORAGE_KEY, AuthProvider } from "app/auth";
import { getConfigForEnv } from "app/config";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "theme";
import { App } from "./App";
import "./index.css";

const config = getConfigForEnv();

const httpLink = createHttpLink({ uri: config.graphqlUri });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  return {
    headers: {
      ...headers,
      authorization: token == null ? "" : `Bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      DeviceInfo: {
        keyFields: ["deviceId"],
      },
    },
  }),
  link: authLink.concat(httpLink),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
