import { SignOutButton, User } from "app/auth";
import { Icon, IconButton, IconType } from "components";
import { intermediaryIdToIntermediaryName } from "domain/intermediary";
import { DrawerMenuIntermediaryFragment } from "generated/graphql/graphql";
import styled from "styled-components";
import { theme } from "../../theme";

interface Props {
  intermediaries: DrawerMenuIntermediaryFragment[];
  user: User | null;
  onClose: () => void;
  activeIntermediaries: string[];
  toggleIntermediary: (intermediaryId: string) => void;
}

export function DrawerMenu(props: Props): JSX.Element {
  const {
    intermediaries,
    user,
    onClose,
    activeIntermediaries,
    toggleIntermediary,
  } = props;

  return (
    <Wrapper>
      <TopSection>
        <IconButton
          type={IconType.CLOSE}
          onClick={onClose}
          color={theme.colors.black[500]}
        />
      </TopSection>
      <UserSection>
        <Icon type={IconType.USER} />
        <ConstrainedText>{user?.email}</ConstrainedText>
        <SignOutButton onSignedOut={onClose} />
      </UserSection>
      <Intermediaries>
        {intermediaries.length > 1 &&
          intermediaries.sort(sortById).map(({ id }) => (
            <p key={id}>
              <input
                type="checkbox"
                id={id}
                value={id}
                onChange={() => toggleIntermediary(id)}
                checked={
                  activeIntermediaries && activeIntermediaries.includes(id)
                }
              />

              <label htmlFor={id}>{intermediaryIdToIntermediaryName(id)}</label>
            </p>
          ))}
      </Intermediaries>
    </Wrapper>
  );
}

function sortById(
  first: DrawerMenuIntermediaryFragment,
  second: DrawerMenuIntermediaryFragment
): number {
  if (first.id > second.id) {
    return 1;
  } else if (second.id > first.id) {
    // eslint-disable-next-line no-magic-numbers
    return -1;
  }
  return 0;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-content: center;
  overflow: hidden;
`;

const TopSection = styled.div`
  margin-top: 48px;
  margin-right: 18px;
  align-self: flex-end;
`;

const UserSection = styled.div`
  align-self: center;
  text-align: center;
  width: 100%;
`;

const Intermediaries = styled.div`
  align-self: center;
  max-height: 600px;
  min-height: 50vh;
  overflow-y: auto;
  width: 100%;
  padding-left: 24px;

  p {
    display: flex;
    gap: 8px;
  }
`;

const ConstrainedText = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
