import { Routes } from "app/routes";
import {
  Button,
  ButtonVariant,
  Column,
  Input,
  Loader,
  Message,
  MessageType,
  NarrowScreen,
} from "components";
import React, { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthHeader } from "./AuthHeader";
import { ResetPasswordStatus } from "./AuthState";
import { useAuth } from "./useAuth";

export function Reset(): JSX.Element {
  const {
    resetPassword,
    resetPasswordState,
    emailInput: email,
    setEmailInput: setEmail,
  } = useAuth();
  const navigate = useNavigate();

  const resetPasswordWithEmail = async (
    event: FormEvent,
    email: string
  ): Promise<void> => {
    event.preventDefault();
    await resetPassword({ email });
  };

  return (
    <NarrowScreen>
      <AuthHeader />
      {resetPasswordState.status === ResetPasswordStatus.RESETTING ? (
        <Loader />
      ) : resetPasswordState.status ===
        ResetPasswordStatus.RESET_MESSAGE_VISIBLE ? (
        <Column>
          <Message type={MessageType.INFO} title="Check your email">
            Please follow the instructions in a newly sent email to set a new
            password.
          </Message>
          <Button
            onClick={(): void => {
              navigate(Routes.SIGN_IN);
            }}
            text="Go to login"
          />
        </Column>
      ) : (
        <Form
          onSubmit={(event): void => {
            resetPasswordWithEmail(event, email);
          }}
        >
          <Column>
            <Message type={MessageType.INFO}>
              Enter your email below to reset your password
            </Message>
            <Input
              label="Email"
              type="email"
              id="email"
              autoComplete="username"
              value={email}
              onChange={(event: FormEvent<HTMLInputElement>): void =>
                setEmail(event.currentTarget.value)
              }
              required
            />
            {resetPasswordState.status === ResetPasswordStatus.FAILED && (
              <Message type={MessageType.ERROR}>
                {resetPasswordState.error.message}
              </Message>
            )}

            <Button text="Reset" type="submit" />
            <Button
              type="button"
              variant={ButtonVariant.SECONDARY}
              text="Cancel"
              onClick={(): void => {
                navigate(Routes.SIGN_IN);
              }}
            />
          </Column>
        </Form>
      )}
    </NarrowScreen>
  );
}

const Form = styled.form`
  width: 100%;
`;
