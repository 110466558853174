import { ReturnInfo } from "generated/graphql/graphql";
import { DateTime } from "luxon";

export enum ShipmentStatus {
  RETURNED = "Returnert",
  SHIPPED = "Utsendt",
  IN_STOCK = "På lager",
}

export const getDeviceShipmentStatus = (
  returnInfo: ReturnInfo | null | undefined,
  shippedAt: DateTime | null | undefined
): ShipmentStatus => {
  if (
    returnInfo &&
    returnInfo.returnedAt &&
    shippedAt &&
    returnInfo?.returnedAt.toString() > shippedAt.toString()
  ) {
    return ShipmentStatus.RETURNED;
  } else if (shippedAt) {
    return ShipmentStatus.SHIPPED;
  } else {
    return ShipmentStatus.IN_STOCK;
  }
};
