import { DeviceOwner } from "../app/dashboard/types";
import { getPostCodeCountry } from "./getCountry";
import { ModalUser } from "./getFormattedUser";

export const getDeviceOwnerFromFormattedUser = (
  user: ModalUser
): DeviceOwner => {
  const getFieldIfFilled = (val: string | undefined) => {
    return val != null && val !== "" ? val : undefined;
  };

  return {
    emailAddress: getFieldIfFilled(user.emailAddress),
    externalUserId: getFieldIfFilled(user.externalUserId),
    firstName: user.firstName ?? "",
    lastName: user.lastName ?? "",
    phoneNumber: getFieldIfFilled(user.phoneNumber),
    postalAddress: {
      country: getPostCodeCountry(user.country),
      postcode: getFieldIfFilled(user.postcode),
      streetAddress: getFieldIfFilled(user.streetAddress),
    },
  };
};
