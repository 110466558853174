import { useState } from "react";
import styled from "styled-components";
import { capitalize, getTranslatedActivationStatus } from "../../utils";
import Switch from "react-switch";
import { LoaderSize, Loader } from "../../components/Loader";
import { theme } from "../../theme";
import {
  ActivationStatus,
  SetDeviceActivationStatusDocument,
} from "generated/graphql/graphql";
import { useMutation } from "@apollo/client";

interface Props {
  initialStatus: ActivationStatus;
  deviceId: string;
}

export function ActivationRowControl(props: Props): JSX.Element {
  const { initialStatus, deviceId } = props;

  const [setDeviceActivationStatusMutation] = useMutation(
    SetDeviceActivationStatusDocument
  );

  const [status, setStatus] = useState<ActivationStatus | undefined>(
    initialStatus
  );
  const [checked, setChecked] = useState(
    initialStatus === ActivationStatus.Activated
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const handleChange = async (nextChecked: boolean) => {
    setIsLoading(true);
    setError(false);
    setChecked(nextChecked);

    let activation = ActivationStatus.Activated;
    if (nextChecked !== true) {
      activation = ActivationStatus.Deactivated;
    }

    try {
      const result = await setDeviceActivationStatusMutation({
        variables: {
          activationStatus: activation,
          deviceIdentifier: { deviceId: deviceId },
        },
      });

      setStatus(
        result.data?.setDeviceActivationStatus.device.activation?.status
      );
    } catch (err) {
      setChecked(!nextChecked);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {isLoading ? (
        <Loader size={LoaderSize.SMALL} />
      ) : error ? (
        <Error>! Prøv igjen</Error>
      ) : (
        capitalize(getTranslatedActivationStatus(status))
      )}
      <StyledSwitch
        disabled={isLoading}
        checked={checked}
        onChange={handleChange}
        onColor="#ffffff"
        offColor="#ffffff"
        onHandleColor="#222222"
        offHandleColor="#222222"
        handleDiameter={15}
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        uncheckedIcon={false}
        checkedIcon={false}
        height={17}
        width={29}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${theme.colors.gray[500]} !important;

  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25),
    inset 1px 1px 1px rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  padding: 4px;
`;

const StyledSwitch = styled(Switch)`
  cursor: pointer;
`;

const Error = styled.div`
  color: ${(props) => props.theme.colors.danger[500]};
`;
