import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  value: string;
  percentage: number;
}

export function SummaryBox(props: Props): JSX.Element {
  const { title, value, percentage } = props;
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Stat>{value}</Stat>
      <Progress>
        <Bar percentage={percentage} />
      </Progress>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 32%;
  align-items: center;
  margin-bottom: 24px;
`;

const Stat = styled.h1`
  font-size: ${({ theme }): string => theme.fontSizes.large}};
  text-transform: uppercase;
  margin: 2px 0;

  letter-spacing: -0.06em;
  font-size: 32px;
  font-weight: 900;
`;

const Title = styled.h2`
  font-weight: 900;
  letter-spacing: -0.03em;
  font-size: ${({ theme }): string => theme.fontSizes.small}};
  text-transform: uppercase;
  margin: 4px 0;
`;

const Progress = styled.div`
  display: flex;
  height: 20px;
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.gray[500]};

  position: relative;
  border-radius: ${({ theme }): string => theme.borderRadius.small};
`;

const Bar = styled.span<ProgressBarProps>`
  background: linear-gradient(90deg, #ffe7ab, #ffa6b0);

  display: inline-block;
  width: ${(props): string => props.percentage.toFixed()}%;
  height: 100%;
  border-radius: ${({ theme }): string => theme.borderRadius.small};
`;

interface ProgressBarProps {
  percentage: number;
}
