import { useAuth } from "app/auth";
import { Routes } from "app/routes";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonVariant,
  Column,
  Input,
  Loader,
  Message,
  MessageType,
  NarrowScreen,
} from "components";
import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { AuthHeader } from "./AuthHeader";
import { SignInStatus } from "./AuthState";
import { VerifyEmail } from "./VerifyEmail";

export function SignIn(): JSX.Element {
  const [password, setPassword] = useState("");
  const {
    signIn,
    signInState,
    emailInput: email,
    setEmailInput: setEmail,
    resendVerificationEmail,
    isResendingVerificationEmail,
    clearLoginState,
  } = useAuth();
  const navigate = useNavigate();

  const signInWithEmailAndPassword = async (
    event: FormEvent,
    email: string,
    password: string
  ): Promise<void> => {
    event.preventDefault();
    await signIn({
      email,
      password,
    });
    navigate(Routes.INDEX);
  };

  return (
    <NarrowScreen>
      <AuthHeader />
      {signInState.status === SignInStatus.SIGNING_IN ? (
        <Loader />
      ) : signInState.status === SignInStatus.EMAIL_NOT_VERIFIED ? (
        <Column>
          <VerifyEmail
            resendVerificationEmail={resendVerificationEmail}
            isResendingVerificationEmail={isResendingVerificationEmail}
            onEmailVerifiedManuallyClicked={clearLoginState}
          />
        </Column>
      ) : (
        <Form
          onSubmit={(event): void => {
            signInWithEmailAndPassword(event, email, password);
          }}
          data-testid="sign-in-form"
        >
          <Column>
            <Input
              label="Email"
              type="email"
              id="email"
              autoComplete="username"
              autoFocus
              value={email}
              onChange={(event: FormEvent<HTMLInputElement>): void =>
                setEmail(event.currentTarget.value)
              }
              required
            />
            <Input
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(event: FormEvent<HTMLInputElement>): void =>
                setPassword(event.currentTarget.value)
              }
              required
            />
            {signInState.status === SignInStatus.FAILED && (
              <Message type={MessageType.ERROR}>
                {signInState.error.message}
              </Message>
            )}
            <Button text="Sign in" type="submit" />
            <Button
              type="button"
              variant={ButtonVariant.SECONDARY}
              text="Register new account"
              onClick={(): void => {
                navigate(Routes.REGISTER);
              }}
            />

            <Button
              type="button"
              variant={ButtonVariant.LINK}
              text="Forgotten password?"
              onClick={(): void => {
                navigate(Routes.RESET_PASSWORD);
              }}
            />
          </Column>
        </Form>
      )}
    </NarrowScreen>
  );
}

const Form = styled.form`
  width: 100%;
`;
