import { useQuery } from "@apollo/client";
import { useAuth } from "app/auth";
import { DrawerMenu } from "app/navigation";
import { Backdrop, NormalScreen, SlideDrawer } from "components";
import {
  DrawerMenuIntermediaryFragment,
  MyIntermediaryScopesDocument,
} from "generated/graphql/graphql";
import { useEffect, useState } from "react";

import { useLocalStorage } from "react-use";
import { DashboardHeader } from "./dashboard-header";
import { DeviceList } from "./DeviceList";
import { Summary } from "./Summary";

export function Dashboard(): JSX.Element {
  const { currentUser } = useAuth();
  const { data: meData } = useQuery(MyIntermediaryScopesDocument);

  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [intermediaries, setIntermediaries] = useState<
    DrawerMenuIntermediaryFragment[]
  >([]);

  const [userPermittedToSeeDeviceNames, setUserPermittedToSeeDeviceNames] =
    useState<boolean>(false);

  const [scopesByIntermediary, setScopesByIntermediary] = useState<
    Map<IntermediaryId, PermissionScope[]>
  >(new Map());

  const [activeIntermediaries, setActiveIntermediaries] = useLocalStorage(
    "activeIntermediaries",
    [],
    {
      deserializer: (intermediaryString: string) =>
        intermediaryString.split(",").filter((element) => {
          return !!element;
        }),
      raw: false,
      serializer: (intermediaries: string[]) =>
        Array.from(intermediaries).join(","),
    }
  );

  useEffect(() => {
    if (meData != null) {
      const intermediaries = meData.me.intermediaries ?? [];
      setIntermediaries([...intermediaries]);

      const scopeMap = new Map(
        intermediaries.map(({ id, permissionScopes }) => {
          if (permissionScopes?.includes("read_intermediary_device_names")) {
            setUserPermittedToSeeDeviceNames(true);
          }
          return [id, permissionScopes ?? []];
        })
      );

      setScopesByIntermediary(scopeMap);
    }
  }, [meData, activeIntermediaries]);

  const toggleMenuDrawer = (): void => {
    setDrawerIsOpen(!drawerIsOpen);
  };

  const toggleIntermediary = (intermediaryId: string): void => {
    const intermediaries = new Set(activeIntermediaries);
    if (intermediaries) {
      if (intermediaries.has(intermediaryId)) {
        intermediaries.delete(intermediaryId);
      } else {
        intermediaries.add(intermediaryId);
      }
      setActiveIntermediaries(Array.from(intermediaries));
    }
  };

  return (
    <>
      <SlideDrawer isOpen={drawerIsOpen}>
        {activeIntermediaries && (
          <DrawerMenu
            user={currentUser}
            intermediaries={intermediaries}
            onClose={toggleMenuDrawer}
            activeIntermediaries={activeIntermediaries}
            toggleIntermediary={toggleIntermediary}
          />
        )}
      </SlideDrawer>

      {drawerIsOpen ? <Backdrop onClick={toggleMenuDrawer} /> : null}

      {
        <>
          <DashboardHeader
            user={currentUser}
            toggleMenuDrawer={toggleMenuDrawer}
          />
          <NormalScreen>
            <Summary activeIntermediaries={activeIntermediaries ?? []} />
            <DeviceList
              activeIntermediaries={activeIntermediaries}
              scopesByIntermediary={scopesByIntermediary}
              userPermittedToSeeDeviceNames={userPermittedToSeeDeviceNames}
            />
          </NormalScreen>
        </>
      }
    </>
  );
}

export type IntermediaryId = string;
export type PermissionScope = string;
