import {
  Button,
  ButtonVariant,
  Loader,
  Message,
  MessageType,
} from "components";
import React from "react";

interface Props {
  resendVerificationEmail: () => void;
  isResendingVerificationEmail: boolean;
  onEmailVerifiedManuallyClicked: () => void;
}

export function VerifyEmail({
  resendVerificationEmail,
  isResendingVerificationEmail,
  onEmailVerifiedManuallyClicked,
}: Props): JSX.Element {
  return (
    <>
      <Message type={MessageType.INFO} title="Check your email">
        A verification email has been sent. Click the link inside it to verify
        your email address. After that, you can log in.
      </Message>
      <Button onClick={onEmailVerifiedManuallyClicked} text="Go to login" />
      {isResendingVerificationEmail ? (
        <Loader />
      ) : (
        <Button
          variant={ButtonVariant.SECONDARY}
          text="Resend email"
          onClick={resendVerificationEmail}
        />
      )}
    </>
  );
}
