import { ApolloError } from "@apollo/client";
import { ErrorType, ServerError } from "./ServerError";

export function apolloErrorToServerError(error: unknown): ServerError {
  if (error instanceof ApolloError) {
    if (error.graphQLErrors != null) {
      for (const graphqlError of error.graphQLErrors) {
        const { exception } = graphqlError.extensions;
        if (isServiceError(exception)) {
          if (exception.serviceErrorCode != null) {
            switch (exception.serviceErrorCode) {
              case "EMAIL_NOT_VERIFIED":
                return new ServerError(
                  ErrorType.EMAIL_NOT_VERIFIED,
                  graphqlError.message
                );
              case "USER_DISABLED":
                return new ServerError(
                  ErrorType.USER_NOT_FOUND,
                  graphqlError.message
                );
              case "USER_ALREADY_EXISTS":
                return new ServerError(
                  ErrorType.USER_ALREADY_EXISTS,
                  graphqlError.message
                );
              case "INVALID_CREDENTIALS":
                return new ServerError(
                  ErrorType.USER_NOT_FOUND,
                  graphqlError.message
                );
            }
          }
        }
        return new ServerError(ErrorType.UNKNOWN, graphqlError.message);
      }
    }
  }
  return new ServerError(ErrorType.UNKNOWN, `${error}`);
}

interface GraphqlException {
  name: string;
}

interface ServiceError extends GraphqlException {
  serviceErrorCode: string | null;
}

function exceptionHasName(exception: unknown): exception is GraphqlException {
  if (exception && typeof exception === "object" && "name" in exception) {
    return true;
  }
  return false;
}

function isServiceError(exception: unknown): exception is ServiceError {
  if (exceptionHasName(exception)) {
    if (exception.name === "CustomError") {
      if ("serviceErrorCode" in exception) {
        return true;
      }
    }
  }
  return false;
}
