import { PropsWithChildren } from "react";
import styled from "styled-components";

export function NormalScreen(props: PropsWithChildren<unknown>): JSX.Element {
  return <NormalScreenWrapper>{props.children}</NormalScreenWrapper>;
}

const NormalScreenWrapper = styled.div`
max-width ${({ theme }): string => `calc(${theme.screenSizes.minimum} * 4)`};
min-width ${({ theme }): string => theme.screenSizes.minimum};
  margin: 0 auto;
  padding: ${({ theme }): string => theme.spacing.small};
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}`;
