import React, { PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
  isOpen: boolean;
}

export function SlideDrawer(props: PropsWithChildren<Props>): JSX.Element {
  const { isOpen, children } = props;
  const classes = isOpen ? "open" : "";
  return <Drawer className={classes}>{children}</Drawer>;
}

const Drawer = styled.div`
  height: 100%;
  background: ${({ theme }): string => theme.colors.white[500]};
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  max-width: 300px;
  min-width: 200px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.25s ease-out;
  border-radius: 90px 0px 0px 8px;

  &.open {
    transform: translateX(0);
  }
`;
