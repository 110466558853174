import React, { PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
  id: string;
  checked: boolean;
  setChecked: (newValue: boolean) => void;
  required?: boolean;
}

export function Checkbox(props: PropsWithChildren<Props>): JSX.Element {
  const { id, checked, setChecked, required, children } = props;

  const toggle = (): void => {
    setChecked(!checked);
  };

  return (
    <CheckboxRow>
      <FakeCheckbox onClick={toggle}>
        {checked ? <Checked /> : <Unchecked />}
      </FakeCheckbox>
      <HiddenCheckbox
        type="checkbox"
        id={id}
        required={required}
        checked={checked}
        onChange={toggle}
      />
      <label htmlFor={id}>{children}</label>
    </CheckboxRow>
  );
}

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  text-transform: uppercase;
  a {
    color: white;
    font-weight: bold;
    line-height: 1.25;
  }

  &:hover svg {
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
    border-radius: 7px;
  }

  &:hover input {
    cursor: pointer;
  }
`;

// This has the same location as the visible fake checkbox
const HiddenCheckbox = styled.input`
  position: absolute;
  appearance: none;
  left: 0;
  bottom: 10px;
  height: 24px;
  width: 24px;
`;

const FakeCheckbox = styled.div`
  padding: 10px 15px 5px 0;
`;

const Checked = (): JSX.Element => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 32a8 8 0 008-8V8a8 8 0 00-8-8H8a8 8 0 00-8 8v16a8 8 0 008 8h16zm1-20L13 24l-6-6 2.828-2.828L13 18.343l9.172-9.171L25 12z"
        fill="#fff"
      />
    </svg>
  );
};

const Unchecked = (): JSX.Element => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none">
      <rect
        opacity={0.2}
        x={32}
        y={32}
        width={32}
        height={32}
        rx={8}
        transform="rotate(180 32 32)"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={64}
          y1={32}
          x2={32}
          y2={63}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0.3} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
    </svg>
  );
};
