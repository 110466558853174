export const theme = {
  borderRadius: {
    large: "32px",
    normal: "8px",
    small: "4px",
  },
  colors: {
    background: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: "#ffffff",
    },
    black: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: "#000000",
    },
    blue: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: "#4285C9",
    },
    danger: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: "#EB3D4F",
    },
    gray: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      200: "#f9f9f9",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: "#e9e9e9",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      700: "#909090",
    },
    link: "#1C60BA",
    success: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: "#14AD75",
    },
    text: "#000000",
    warning: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: "#DC8B21",
    },
    white: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      500: "#ffffff",
    },
  },
  fontSizes: {
    large: "18px",
    normal: "16px",
    small: "14px",
  },
  letterSpacing: {
    large: "0.15em",
    medium: "0.1em",
    normal: "0.05em",
  },
  screenSizes: {
    minimum: "320px",
  },
  spacing: {
    large: "48px",
    normal: "16px",
    small: "8px",
  },
};
