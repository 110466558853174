export function intermediaryIdToIntermediaryName(
  intermediaryId?: string
): string {
  if (intermediaryId == null) {
    return "";
  }
  switch (intermediaryId) {
    case "FINNÅS_KRAFTLAG":
      return "Finnås";
    case "HAUGALAND_KRAFT_MARKED":
      return "Haugaland";
    case "HELGELAND_KRAFT_STRØM":
      return "Helgeland";
    case "NTE":
      return "NTE";
    case "NTNU_SMARTLAB":
      return "NTNU Smartlab";
    case "OPT-E":
      return "Opt-E";
    case "PBC_FOCUS":
      return "PBC Focus";
    default:
      return titleCaseAndRemoveCommonSuffixes(intermediaryId);
  }
}

function titleCaseAndRemoveCommonSuffixes(name: string) {
  const lowercased = name.toLowerCase().replaceAll("_", " ");
  const shorter = removeCommonSuffixes(lowercased);
  return shorter
    .split(" ")
    .map((word) => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    })
    .join(" ");
}

function removeCommonSuffixes(name: string) {
  return name.replace(/ (kraft|energi|nett|strøm)$/, "");
}
