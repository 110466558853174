/* eslint-disable no-magic-numbers */
import { useMutation } from "@apollo/client";
import { UpdateDeviceOwnerDocument } from "generated/graphql/graphql";
import { FormEvent, useState } from "react";
import styled, { css } from "styled-components";
import {
  EditModalState,
  EditModalStatus,
} from "../../../components/ConfirmOrCancel";
import {
  checkIfFormFieldIsValid,
  checkIfFormIsValid,
  getDeviceOwnerFromFormattedUser,
  getFormattedUser,
  ModalUser,
  UpdateOwnerInputFormErrors,
} from "../../../utils";
import { TranslatedCountry } from "../../../utils/getCountry";
import { DeviceModalColumn, RowContent } from "./DeviceModalColumn";

interface EditDeviceOwnerColumnProps {
  ownedBy: ModalUser;
  setOwnedBy: (arg: ModalUser) => void;
  deviceId: string;
  isEditable: boolean;
  state: EditModalState;
  setState: (arg: EditModalState) => void;
}

export const EditDeviceOwnerColumn = ({
  ownedBy,
  setOwnedBy,
  deviceId,
  isEditable,
  state,
  setState,
}: EditDeviceOwnerColumnProps): JSX.Element => {
  const [updateDeviceOwnerMutation] = useMutation(UpdateDeviceOwnerDocument);

  const [formData, setFormData] = useState(ownedBy);
  const [formErrors, setFormErrors] = useState<UpdateOwnerInputFormErrors>({
    country: null,
    emailAddress: null,
    firstName: null,
    lastName: null,
    postcode: null,
  });

  const emptyFormErrors = () => {
    setFormErrors({
      country: null,
      emailAddress: null,
      firstName: null,
      lastName: null,
      postcode: null,
    });
  };

  const onSave = async () => {
    const isValid = checkIfFormIsValid(formData, setFormErrors);

    if (!isValid) {
      return;
    }

    setState({ status: EditModalStatus.SAVING });

    const deviceOwner = getDeviceOwnerFromFormattedUser(formData);
    try {
      const { data: updatedDevice } = await updateDeviceOwnerMutation({
        variables: { deviceId, deviceOwner: deviceOwner },
      });

      setState({ status: EditModalStatus.CLOSED });
      setOwnedBy(
        getFormattedUser(updatedDevice?.updateDeviceOwner?.ownedBy ?? undefined)
      );
    } catch (error) {
      if (error instanceof Error) {
        setState({ error: error.message, status: EditModalStatus.FAILED });
      } else {
        setState({ error: `${error}`, status: EditModalStatus.FAILED });
      }
    }
  };

  const onCancel = () => {
    emptyFormErrors();
    setOwnedBy(ownedBy);
    setState({ status: EditModalStatus.CLOSED });
  };

  const OwnerRowsInEditMode: RowContent[] = [
    {
      content: (
        <StyledInput
          autoFocus
          value={formData.firstName}
          error={formErrors.firstName}
          placeholder="—"
          onFocus={(event) => (event.target.placeholder = "")}
          onBlur={(event) => {
            checkIfFormFieldIsValid(
              formData,
              formErrors,
              setFormErrors,
              "firstName"
            );
            event.target.placeholder = "—";
          }}
          onChange={(event: FormEvent<HTMLInputElement>) =>
            setFormData({ ...formData, firstName: event.currentTarget.value })
          }
        />
      ),
      title: "Fornavn",
    },
    {
      content: (
        <StyledInput
          value={formData.lastName}
          error={formErrors.lastName}
          placeholder="—"
          onFocus={(event) => (event.target.placeholder = "")}
          onBlur={(event) => {
            checkIfFormFieldIsValid(
              formData,
              formErrors,
              setFormErrors,
              "lastName"
            );
            event.target.placeholder = "—";
          }}
          onChange={(event: FormEvent<HTMLInputElement>) =>
            setFormData({ ...formData, lastName: event.currentTarget.value })
          }
        />
      ),
      title: "Etternavn",
    },
    {
      content: (
        <StyledInput
          value={formData.externalUserId}
          placeholder="—"
          onFocus={(event) => (event.target.placeholder = "")}
          onChange={(event: FormEvent<HTMLInputElement>) =>
            setFormData({
              ...formData,
              externalUserId: event.currentTarget.value,
            })
          }
        />
      ),
      title: "Kundenr",
    },
    {
      content: (
        <StyledInput
          value={formData.phoneNumber}
          placeholder="—"
          onFocus={(event) => (event.target.placeholder = "")}
          onChange={(event: FormEvent<HTMLInputElement>) =>
            setFormData({ ...formData, phoneNumber: event.currentTarget.value })
          }
        />
      ),
      title: "Telefonnr",
    },
    {
      content: (
        <StyledInput
          value={formData.emailAddress}
          error={formErrors.emailAddress}
          placeholder="—"
          onFocus={(event) => (event.target.placeholder = "")}
          onBlur={() =>
            checkIfFormFieldIsValid(
              formData,
              formErrors,
              setFormErrors,
              "emailAddress"
            )
          }
          onChange={(event: FormEvent<HTMLInputElement>) =>
            setFormData({
              ...formData,
              emailAddress: event.currentTarget.value,
            })
          }
        />
      ),
      title: "E-post",
    },
    {
      content: (
        <StyledInput
          value={formData.streetAddress}
          placeholder="—"
          onFocus={(event) => (event.target.placeholder = "")}
          onChange={(event: FormEvent<HTMLInputElement>) =>
            setFormData({
              ...formData,
              streetAddress: event.currentTarget.value,
            })
          }
        />
      ),
      title: "Gateadresse",
    },
    {
      content: (
        <StyledInput
          value={formData.postcode}
          error={formErrors.postcode}
          placeholder="—"
          onFocus={(event) => (event.target.placeholder = "")}
          onBlur={() =>
            checkIfFormFieldIsValid(
              formData,
              formErrors,
              setFormErrors,
              "postcode"
            )
          }
          onChange={(event: FormEvent<HTMLInputElement>) =>
            setFormData({ ...formData, postcode: event.currentTarget.value })
          }
        />
      ),
      title: "Postnummer",
    },
    {
      content: (
        <StyledSelect
          value={formData.country}
          error={formErrors.country}
          onBlur={() =>
            checkIfFormFieldIsValid(
              formData,
              formErrors,
              setFormErrors,
              "country"
            )
          }
          onChange={(event: FormEvent<HTMLSelectElement>) =>
            setFormData({
              ...formData,
              country: event.currentTarget.value as TranslatedCountry,
            })
          }
        >
          {(Object.values(TranslatedCountry) as Array<TranslatedCountry>).map(
            (country, index) => {
              return (
                <option key={`country:${index}`} value={country}>
                  {country}
                </option>
              );
            }
          )}
        </StyledSelect>
      ),
      title: "Land",
    },
  ];

  return (
    <>
      <DeviceModalColumn
        header="Eier"
        editable={isEditable}
        onEdit={() => setState({ status: EditModalStatus.OPEN })}
        confirmOrCancel={{
          onCancel,
          onConfirm: onSave,
          state,
        }}
        rows={OwnerRowsInEditMode}
      />
      <ErrorText>
        {Object.values(formErrors)
          .filter((error) => error != null)
          .join(". ")}
      </ErrorText>
    </>
  );
};

const StyledInput = styled.input<{ error?: string | null }>`
  display: flex;
  justify-content: center;
  text-align: center;

  background: ${(props) => props.theme.colors.gray[200]};

  font-size: 16px;
  font-weight: bold;
  color: black;

  width: 100%;
  height: 2rem;

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.blue[500]};
    border-radius: 0px;
  }

  ${(props) =>
    props.error
      ? css`
          border: 2px solid ${(props) => props.theme.colors.danger[500]};
        `
      : css`
          &:not(:focus) {
            border: 0;
          }
        `}
`;

const StyledSelect = styled.select<{ error?: string | null }>`
  display: flex;
  justify-content: center;
  text-align: center;

  background: ${(props) => props.theme.colors.gray[200]};
  font-size: 16px;
  font-weight: bold;
  color: black;

  overflow: scroll;

  width: 100%;
  min-height: 2rem;

  &:focus {
    outline: none;
    border: 2px solid ${({ theme }): string => theme.colors.blue[500]};
  }

  ${(props) =>
    props.error
      ? css`
          border-width: 1px;
          border-color: ${(props) => props.theme.colors.danger[500]};
        `
      : css`
          &:not(:focus) {
            border: 0;
          }
        `}
`;

const ErrorText = styled.div`
  position: absolute;
  bottom: 10px;
  color: ${(props) => props.theme.colors.danger[500]};
`;
