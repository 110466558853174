import { User } from "app/auth";
import { IconButton, IconType } from "components";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import brand from "../../../static/hark.svg";

interface Props {
  user: User | null;
  toggleMenuDrawer: () => void;
}

export function DashboardHeader(props: Props): JSX.Element {
  const { toggleMenuDrawer } = props;
  const [, setParams] = useSearchParams();

  return (
    <Header>
      <Left>
        <Brand src={brand} onClick={() => setParams(undefined)} />
      </Left>

      <Center>dashboard</Center>

      <Right>
        <IconButton
          type={IconType.MENU}
          onClick={toggleMenuDrawer}
          color={"#000000"}
        />
      </Right>
    </Header>
  );
}

const Header = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 4px;
`;

const Left = styled.div`
  flex-basis: 0;
  margin-left: 48px;
`;

const Center = styled.div`
  font-family: "Noto Serif";
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.08em;
  text-align: center;
`;

const Right = styled.div`
  margin-right: 18px;
  display: flex;
  justify-content: flex-end;
`;

const Brand = styled.img`
  cursor: pointer;
`;
