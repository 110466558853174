import React from "react";
import styled from "styled-components";

interface Props {
  onClick: () => void;
}

export function Backdrop(props: Props): JSX.Element {
  const { onClick } = props;
  return <BackdropLayer onClick={onClick} />;
}

const BackdropLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
`;
