export enum ErrorType {
  USER_NOT_FOUND = "USER_NOT_FOUND",
  USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  INVALID_EMAIL_ADDRESS = "INVALID_EMAIL_ADDRESS",
  EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED",
  UNKNOWN = "UNKNOWN",
}

export class ServerError extends Error {
  type: ErrorType;

  constructor(type: ErrorType, message: string) {
    super(message);
    this.type = type;
    this.name = ServerError.name;
  }
}
