import { SubscriptionType } from "generated/graphql/graphql";

export const getFormattedSubscriptionType = (
  subscriptionType: SubscriptionType
): string => {
  switch (subscriptionType) {
    case SubscriptionType.Personal:
      return "Privatkunde";
    case SubscriptionType.Business:
      return "Bedriftskunde";
    case SubscriptionType.Pilot:
      return "Pilotbruker";
    default:
      throw new Error("Could not parse subscription type");
  }
};
