import { DateTime } from "luxon";
import { capitalize, getDeviceShipmentStatus } from "../../utils";
import {
  DashboardListDeviceFragment,
  DashboardListDeviceFragmentDoc,
  ModalDeviceFragment,
  ModalDeviceFragmentDoc,
  SubscriptionType,
} from "generated/graphql/graphql";
import { FragmentType } from "generated/graphql/fragment-masking";
import { ListDevice, ShippedDevice } from "./types";

export const graphqlListDeviceToListDevice = (
  graphqlDevice: FragmentType<typeof DashboardListDeviceFragmentDoc>
): ListDevice => {
  const {
    deviceId,
    deviceName,
    activation,
    diagnostic,
    intermediaryId,
    ownedBy,
    recentMeter,
    returnInfo,
    shippedAt,
    type,
    updateStatus,
  } = graphqlDevice as DashboardListDeviceFragment;

  const device: ListDevice = {
    activation: activation ?? undefined,
    deviceId,
    intermediaryId: intermediaryId ?? undefined,
    isUpdating:
      !!updateStatus &&
      !updateStatus.finishedAt &&
      DateTime.now() > DateTime.fromISO(updateStatus.startedAt),
    name: deviceName ?? undefined,
    ownedBy: ownedBy ?? undefined,
    recentMeter: {
      meterId: recentMeter?.meterId,
      producer: capitalize(recentMeter?.producer.toLowerCase()),
    },
    returnInfo,
    shipmentStatus: getDeviceShipmentStatus(
      returnInfo,
      shippedAt ? DateTime.fromISO(shippedAt) : undefined
    ),
    type,
  };

  if (diagnostic != null && shippedAt != null) {
    const { lastMessageAt, lastPingAt, signalStrength } = diagnostic;

    if (lastMessageAt != null && lastMessageAt > shippedAt) {
      device.lastMessageAt = DateTime.fromISO(lastMessageAt);
    }
    if (lastPingAt != null && lastPingAt > shippedAt) {
      device.lastPingAt = DateTime.fromISO(lastPingAt);
    }

    device.lastSeenAt = selectLastSeenAt({
      lastMessageAt: device.lastMessageAt,
      lastPingAt: device.lastPingAt,
    });

    device.signalStrength =
      signalStrength && device.lastSeenAt ? signalStrength : undefined;
  }

  return device;
};

export const graphqlDeviceToDevice = (
  graphqlDevice?: FragmentType<typeof ModalDeviceFragmentDoc>
): ShippedDevice | undefined => {
  if (!graphqlDevice) return undefined;

  const {
    deviceId,
    deviceName,
    activation,
    diagnostic,
    intermediaryId,
    ownedBy,
    recentMeter,
    returnInfo,
    shippedAt,
    shippedTo,
    subscription,
    type,
    updateStatus,
  } = graphqlDevice as ModalDeviceFragment;

  const device: ShippedDevice = {
    activation: activation ?? undefined,
    deviceId,
    firmwareVersion: diagnostic?.firmwareVersion ?? undefined,
    intermediaryId: intermediaryId ?? undefined,
    isUpdating:
      !!updateStatus &&
      !updateStatus.finishedAt &&
      DateTime.now() > DateTime.fromISO(updateStatus.startedAt),
    name: deviceName ?? undefined,
    ownedBy: ownedBy ?? undefined,
    recentMeter: {
      meterId: recentMeter?.meterId,
      producer: capitalize(recentMeter?.producer.toLowerCase()),
    },
    returnInfo,
    shipmentStatus: getDeviceShipmentStatus(
      returnInfo,
      shippedAt ? DateTime.fromISO(shippedAt) : undefined
    ),
    shippedAt: shippedAt ? DateTime.fromISO(shippedAt) : undefined,
    shippedTo: shippedTo ?? undefined,
    subscription: subscription ?? {
      subscriptionType: SubscriptionType.Personal,
    },
    type,
    updateStatus: updateStatus
      ? {
          finishedAt: updateStatus.finishedAt
            ? DateTime.fromISO(updateStatus.finishedAt)
            : undefined,
          progressPercent: updateStatus.progressPercent,
          startedAt: DateTime.fromISO(updateStatus.startedAt),
          updatedAt: DateTime.fromISO(updateStatus.updatedAt),
          version: updateStatus.version,
        }
      : undefined,
  };

  if (diagnostic != null && shippedAt != null) {
    const { lastMessageAt, lastPingAt, publishInterval, signalStrength } =
      diagnostic;

    if (lastMessageAt != null && lastMessageAt > shippedAt) {
      device.lastMessageAt = DateTime.fromISO(lastMessageAt);
    }
    if (lastPingAt != null && lastPingAt > shippedAt) {
      device.lastPingAt = DateTime.fromISO(lastPingAt);
    }

    device.lastSeenAt = selectLastSeenAt({
      lastMessageAt: device.lastMessageAt,
      lastPingAt: device.lastPingAt,
    });

    device.publishInterval =
      publishInterval != null ? publishInterval : undefined;

    device.signalStrength =
      signalStrength && device.lastSeenAt ? signalStrength : undefined;
  }

  return device;
};

function selectLastSeenAt({
  lastPingAt,
  lastMessageAt,
}: {
  lastPingAt: DateTime | undefined;
  lastMessageAt: DateTime | undefined;
}): DateTime | undefined {
  if (lastPingAt && lastMessageAt) {
    return lastMessageAt > lastPingAt ? lastMessageAt : lastPingAt;
  } else if (lastMessageAt) {
    return lastMessageAt;
  } else if (lastPingAt) {
    return lastPingAt;
  }
}
