import { PropsWithChildren } from "react";
import styled from "styled-components";

export function NarrowScreen(props: PropsWithChildren<unknown>): JSX.Element {
  return <NarrowScreenWrapper>{props.children}</NarrowScreenWrapper>;
}

const NarrowScreenWrapper = styled.div`
  max-width ${({ theme }): string =>
    `calc(${theme.screenSizes.minimum} * 1.3)`};
  min-width ${({ theme }): string => theme.screenSizes.minimum};
  margin: 0 auto;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}`;
