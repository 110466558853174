export const magnifierIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.3137"
        cy="11.3137"
        r="7"
        transform="rotate(45 11.3137 11.3137)"
        stroke={color ?? "white"}
        strokeWidth="2"
      />
      <line
        x1="15.5562"
        y1="15.5566"
        x2="21.2131"
        y2="21.2134"
        stroke={color ?? "white"}
        strokeWidth="2"
      />
    </svg>
  );
};
