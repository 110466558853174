import styled from "styled-components";
import ecoMonitor from "../../static/hark.svg";

export function AuthHeader(): JSX.Element {
  return (
    <>
      <TitleImage src={ecoMonitor} alt="Hark" />
      <Heading>Dashboard</Heading>
    </>
  );
}

const TitleImage = styled.img`
  margin-top: 20px;
  margin-bottom: 15px;
`;

const Heading = styled.h1`
  color: ${({ theme }): string => theme.colors.black[500]};
  font-size: ${({ theme }): string => theme.fontSizes.large};
  text-transform: uppercase;
  letter-spacing: ${({ theme }): string => theme.letterSpacing.medium};
  margin-bottom: 50px;
`;
