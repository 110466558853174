import { checkIcon } from "./checkIcon";
import { closeIcon } from "./closeIcon";
import { editIcon } from "./editIcon";
import { ellipseIcon } from "./ellipseIcon";
import { exclamationIcon } from "./exclamationIcon";
import { magnifierIcon } from "./magnifierIcon";
import { menuIcon } from "./menuIcon";
import { refreshIcon } from "./refreshIcon";
import { userIcon } from "./userIcon";

interface Props {
  type: IconType;
  color?: string;
  onClick?: () => void;
}

export function Icon(props: Props): JSX.Element {
  const { type, color } = props;
  const src: JSX.Element = getIconSource(type, color);

  return src;
}

export function getIconSource(type: IconType, color?: string): JSX.Element {
  switch (type) {
    case IconType.CLOSE:
      return closeIcon(color);
    case IconType.MAGNIFIER:
      return magnifierIcon(color);
    case IconType.MENU:
      return menuIcon(color);
    case IconType.USER:
      return userIcon(color);
    case IconType.CHECK:
      return checkIcon(color);
    case IconType.ELLIPSE:
      return ellipseIcon(color);
    case IconType.EXCLAMATION:
      return exclamationIcon(color);
    case IconType.REFRESH:
      return refreshIcon(color);
    case IconType.EDIT:
    default:
      return editIcon(color);
  }
}

export enum IconType {
  CLOSE = "CLOSE",
  EDIT = "EDIT",
  USER = "USER",
  MAGNIFIER = "MAGNIFIER",
  MENU = "MENU",
  CHECK = "CHECK",
  ELLIPSE = "ELLIPSE",
  EXCLAMATION = "EXCLAMATION",
  REFRESH = "REFRESH",
}
