export const editIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 9.75V12H2.25L0 9.75Z" fill={color ?? "white"} />
      <path
        d="M3 11.25L0.75 9L8.25 1.5L9 0.75L10.5 2.25L9.75 3L10.5 3.75L3 11.25Z"
        fill={color ?? "white"}
      />
    </svg>
  );
};
