import brand from "../../static/ecomon.svg";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export function NotFoundPage(): JSX.Element {
  return (
    <Container>
      <NavLink to={"/"}>
        <Brand src={brand} />
        <Title>Page not found, click here to go to dashboard</Title>
      </NavLink>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Brand = styled.img`
  padding: 18px;
  margin-top: 7px;
  cursor: pointer;
  width: 100%;
`;

const Title = styled.h2`
  font-weight: 900;
  letter-spacing: -0.03em;
  font-size: ${({ theme }): string => theme.fontSizes.large}};
  margin: 4px 0;
`;
