export const refreshIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9C15 10.3845 14.5895 11.7378 13.8203 12.889C13.0511 14.0401 11.9579 14.9373 10.6788 15.4672C9.3997 15.997 7.99223 16.1356 6.63437 15.8655C5.2765 15.5954 4.02922 14.9287 3.05025 13.9497C2.07128 12.9708 1.4046 11.7235 1.1345 10.3656C0.864406 9.00777 1.00303 7.6003 1.53284 6.32122C2.06266 5.04213 2.95986 3.94888 4.11101 3.17971C5.26215 2.41054 6.61553 2 8 2L8 3.75C6.96165 3.75 5.94661 4.05791 5.08326 4.63478C4.2199 5.21166 3.54699 6.0316 3.14963 6.99091C2.75227 7.95022 2.6483 9.00582 2.85088 10.0242C3.05345 11.0426 3.55346 11.9781 4.28769 12.7123C5.02192 13.4465 5.95738 13.9466 6.97578 14.1491C7.99418 14.3517 9.04978 14.2477 10.0091 13.8504C10.9684 13.453 11.7883 12.7801 12.3652 11.9167C12.9421 11.0534 13.25 10.0384 13.25 9H15Z"
        fill={color ?? "white"}
      />
      <path d="M8 6L11 3L8 0L8 6Z" fill={color ?? "white"} />
    </svg>
  );
};
