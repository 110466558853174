import { Register, Reset, SignIn, useAuth } from "app/auth";
import { Dashboard } from "app/dashboard";
import { Routes as EcomonRoutes } from "app/routes";
import { Loader, NarrowScreen } from "components";
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { NotFoundPage } from "./app/not-found-page/NotFoundPage";

export function App(): JSX.Element {
  const { isFetchingUser } = useAuth();
  if (isFetchingUser) {
    return (
      <NarrowScreen>
        <Loader />
      </NarrowScreen>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path={EcomonRoutes.SIGN_IN} element={<SignIn />} />
        <Route path={EcomonRoutes.REGISTER} element={<Register />} />
        <Route path={EcomonRoutes.RESET_PASSWORD} element={<Reset />} />
        <Route
          path={EcomonRoutes.INDEX}
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route path={"*"} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (currentUser == null) {
    return (
      <Navigate
        to={{
          pathname: EcomonRoutes.SIGN_IN,
        }}
        state={{ from: location }}
      />
    );
  }

  return children;
}
