import { useQuery } from "@apollo/client";
import { ModalDeviceDocument } from "generated/graphql/graphql";
import styled from "styled-components";
import { Loader } from "../../../components";
import { Modal, ModalProps } from "../../../components/modal";
import { intermediaryIdToIntermediaryName } from "../../../domain/intermediary";
import { IntermediaryId, PermissionScope } from "../Dashboard";
import { graphqlDeviceToDevice } from "../graphqlMappers";
import { DeviceModalContent } from "./DeviceModalContent";

const DeviceModalHeader = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;

  .deviceId {
    font-size: 22px;
  }

  .type {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    border-radius: 50%;

    background: ${(props) => props.theme.colors.white[500]};
    font-size: 16px;
    color: ${(props) => props.theme.colors.blue[500]};
  }
`;

interface DeviceModalProps extends ModalProps {
  deviceId?: string;
  scopesByIntermediary: Map<IntermediaryId, PermissionScope[]>;
}

export const DeviceModal = ({
  deviceId,
  scopesByIntermediary,
  open,
  onClose,
}: DeviceModalProps): JSX.Element => {
  const { data, loading } = useQuery(ModalDeviceDocument, {
    variables: { input: { identifier: { deviceId } } },
  });

  const device = graphqlDeviceToDevice(data?.device.device);

  return (
    <Modal
      open={open}
      header={
        device ? (
          <DeviceModalHeader>
            <div className="deviceId">
              {device.name ? deviceId + " (" + device.name + ")" : deviceId}
            </div>
            <div className="type">
              {device.type === "HAN_LTEM" ? "4G" : "Wifi"}
            </div>
            {intermediaryIdToIntermediaryName(device.intermediaryId)}
          </DeviceModalHeader>
        ) : (
          <div className="deviceId">{deviceId}</div>
        )
      }
      onClose={onClose}
    >
      {device ? (
        <DeviceModalContent
          device={device}
          scopesByIntermediary={scopesByIntermediary}
        />
      ) : loading ? (
        <Loader />
      ) : (
        <div>Det oppsto en feil. Vennligst prøv igjen</div>
      )}
    </Modal>
  );
};
