import { devConfig } from "./dev";
import { localConfig } from "./local";
import { prodConfig } from "./prod";

export interface AppConfig {
  graphqlUri: string;
}

export function getConfigForEnv(): AppConfig {
  const env = process.env.REACT_APP_ENV;
  switch (env?.toLocaleUpperCase()) {
    case Env.PROD:
      return prodConfig;
    case Env.DEV:
      return devConfig;
    case Env.LOCAL:
    case Env.TEST:
      return localConfig;
    default:
      throw new Error(
        `The provided env ${env} is not supported. Please provide one of the supported values(${Object.values(
          Env
        )}) as REACT_APP_ENV`
      );
  }
}

enum Env {
  PROD = "PROD",
  DEV = "DEV",
  LOCAL = "LOCAL",
  TEST = "TEST,",
}
