import { PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
  variant?: ColumnVariant;
}

export function Column(props: PropsWithChildren<Props>): JSX.Element {
  return (
    <StyledColumn tight={props.variant === ColumnVariant.TIGHT}>
      {props.children}
    </StyledColumn>
  );
}

const StyledColumn = styled.div<{ tight?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > :not(:last-child) {
    margin-bottom: ${({ tight }) => (tight ? "0.5rem" : "1.5rem")};
  }
`;

export enum ColumnVariant {
  TIGHT = "TIGHT",
  NORMAL = "NORMAL",
}
