import { TranslatedCountry } from "./getCountry";
import { ModalUser } from "./getFormattedUser";

export interface UpdateOwnerInputFormErrors {
  country: string | null;
  firstName: string | null;
  lastName: string | null;
  postcode: string | null;
  emailAddress: string | null;
}

const isEmpty = (val: string | undefined) => {
  return val === "" || val == null;
};

const fourDigitsRegex = /^\d{4}$/;
const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

// Check all fields before submitting
export const checkIfFormIsValid = (
  owner: ModalUser,
  setFormErrors: React.Dispatch<
    React.SetStateAction<UpdateOwnerInputFormErrors>
  >
) => {
  const countryIsNorway = owner.country === TranslatedCountry.NORGE;
  const countryIsValid = owner.country
    ? Object.values(TranslatedCountry).includes(owner.country)
    : true;

  const errors = {
    country: !countryIsValid ? "Landet er ikke gjenkjent" : null,
    emailAddress: !isEmpty(owner.emailAddress)
      ? emailRegex.test(owner.emailAddress ?? "")
        ? null
        : "E-postadressen er på feil format"
      : null,
    firstName: isEmpty(owner.firstName) ? "Fornavn er påkrevd" : null,
    lastName: isEmpty(owner.lastName) ? "Etternavn er påkrevd" : null,
    postcode: !isEmpty(owner.postcode)
      ? countryIsNorway
        ? fourDigitsRegex.test(owner.postcode || "")
          ? null
          : "Postnummeret er på feil format"
        : null
      : null,
  };

  setFormErrors(errors);

  if (
    Object.values(errors).filter((error) => typeof error === "string").length >
    0
  ) {
    return false;
  }
  return true;
};

// Check just the relevant field(s) on blur
export const checkIfFormFieldIsValid = (
  owner: ModalUser,
  formErrors: UpdateOwnerInputFormErrors,
  setFormErrors: React.Dispatch<
    React.SetStateAction<UpdateOwnerInputFormErrors>
  >,
  field: keyof ModalUser
) => {
  if (field === "firstName") {
    setFormErrors({
      ...formErrors,
      firstName: isEmpty(owner.firstName) ? "Fornavn er påkrevd" : null,
    });
  }
  if (field === "lastName") {
    setFormErrors({
      ...formErrors,
      lastName: isEmpty(owner.lastName) ? "Etternavn er påkrevd" : null,
    });
  }
  if (field === "postcode") {
    const countryIsNorway = owner.country === TranslatedCountry.NORGE;
    setFormErrors({
      ...formErrors,
      postcode: !isEmpty(owner.postcode)
        ? countryIsNorway
          ? fourDigitsRegex.test(owner.postcode || "")
            ? null
            : "Postnummeret er på feil format"
          : null
        : null,
    });
  }
  if (field === "country") {
    const countryIsNorway = owner.country === TranslatedCountry.NORGE;
    const countryIsValid = owner.country
      ? Object.values(TranslatedCountry).includes(owner.country)
      : true;
    setFormErrors({
      ...formErrors,
      country: !countryIsValid ? "Landet er ikke gjenkjent" : null,
      postcode: !isEmpty(owner.postcode)
        ? countryIsNorway
          ? fourDigitsRegex.test(owner.postcode || "")
            ? null
            : "Postnummeret er på feil format"
          : null
        : null,
    });
  }
  if (field === "emailAddress") {
    setFormErrors({
      ...formErrors,
      emailAddress: !isEmpty(owner.emailAddress)
        ? emailRegex.test(owner.emailAddress ?? "")
          ? null
          : "E-postadressen er på feil format"
        : null,
    });
  }
};
