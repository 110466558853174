import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface Props {
  label: string;
  id: string;
  autoComplete?:
    | "on"
    | "off"
    | "username"
    | "current-password"
    | "new-password";
  autoFocus?: boolean;
  type?: "email" | "password" | "text";
  required?: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function Input(props: Props): JSX.Element {
  const {
    label,
    id,
    type,
    autoComplete,
    autoFocus,
    value,
    required,
    onChange,
  } = props;
  return (
    <Field>
      <Label htmlFor={id}>{label}</Label>
      <Textfield
        type={type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        id={id}
        name={id}
        value={value}
        required={required}
        onChange={onChange}
      />
    </Field>
  );
}

const Textfield = styled.input`
  width: 100%;
  padding: 15px 10px;
  font-size: 18px;
  border-radius: ${({ theme }): string => theme.borderRadius.normal};
  border-color: ${({ theme }): string => theme.colors.gray[500]};
  border-width: 0;
  background: ${({ theme }): string => theme.colors.gray[500]};

  &:focus {
    outline: none;
  }

  &:invalid {
    box-shadow: none;
  }
`;

const Label = styled.label`
  color: ${({ theme }): string => theme.colors.black[500]};
  font-size: ${({ theme }): string => theme.fontSizes.normal};
  letter-spacing: ${({ theme }): string => theme.letterSpacing.medium};
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  display: block;
`;

const Field = styled.div``;
