/* eslint-disable no-magic-numbers */
import { FormEvent } from "react";
import styled from "styled-components";
import {
  ConfirmOrCancel,
  EditModalState,
  EditModalStatus,
} from "./ConfirmOrCancel";

interface Props {
  value: string;
  state: EditModalState;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  onSave: () => void;
  closeModal: () => void;
}

export function EditModal({
  value,
  state,
  onChange,
  onSave,
  closeModal,
}: Props): JSX.Element | null {
  if (state.status === EditModalStatus.CLOSED) {
    return null;
  }

  return (
    <EditModalStyled>
      <Form
        error={state.status === EditModalStatus.FAILED}
        onSubmit={(event: FormEvent<HTMLElement>): void => {
          event.preventDefault();
          onSave();
        }}
      >
        <input
          className="deviceName"
          autoFocus
          value={value}
          onChange={onChange}
        />
        <ConfirmOrCancel
          state={state}
          onCancel={closeModal}
          onConfirm={onSave}
        />
      </Form>
    </EditModalStyled>
  );
}

export const Form = styled.form<{ error?: boolean }>`
  display: flex;
  border-width: 0 0 1px 0;
  border-color: white;
  border-style: solid;
  background: ${({ theme }): string => theme.colors.gray[200]};

  padding: 0 6px;

  height: 2rem;
  width: 100%;

  & input {
    border: 0;
    background: transparent;
    font-size: 16px;
    font-weight: bold;
    color: ${(props) =>
      props.error ? props.theme.colors.danger[500] : "black"};
    width: 100%;
    height: 2rem;

    &:focus {
      outline: none;
      border 2px solid ${({ theme }): string => theme.colors.blue[500]};
    }

    &:invalid {
      box-shadow: none;
    }
  }
  display: flex;
`;

const EditModalStyled = styled.div`
  position: absolute;
  left: 0px;
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.background[500]};
`;
