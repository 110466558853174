/* eslint-disable no-magic-numbers */
import styled from "styled-components";
import { IconButton, IconButtonSize, IconType } from "../../../components";
import {
  ConfirmOrCancel,
  ConfirmOrCancelProps,
  EditModalStatus,
} from "../../../components/ConfirmOrCancel";
import { theme } from "../../../theme";

const Column = styled.div`
  .label {
    font-weight: 900;
    font-size: 22px;
    margin-bottom: 4px;
    color: black;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 0.5rem;

  height: 2rem;
  margin-bottom: 0.5rem;
  align-items: center;
`;

const Title = styled.div`
  color: ${({ theme }): string => theme.colors.gray[700]};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: ${({ theme }): string => theme.colors.gray[200]};
  font-weight: 600;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  a {
    color: black;
    max-width: 100%;
  }

  div:has(> input) {
    border-width: 0 0 1px 0;
    border-color: black;
  }
`;

const ButtonCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 4px;
  background: ${({ theme }): string => theme.colors.gray[500]};

  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25),
    inset 1px 1px 1px rgba(255, 255, 255, 0.25);

  :hover {
    cursor: pointer;
  }
`;

export interface RowContent {
  title: string;
  content?: string | JSX.Element;
}

interface DeviceModalColumnProps {
  header: string;
  confirmOrCancel?: ConfirmOrCancelProps;
  rows: RowContent[];
  editable?: boolean;
  onEdit?: () => void;
}

export const DeviceModalColumn = ({
  header,
  confirmOrCancel,
  rows,
  editable,
  onEdit,
}: DeviceModalColumnProps): JSX.Element => {
  const emptyCell = "—";
  return (
    <Column>
      <Row>
        <div className="label">{header}</div>
        {onEdit != null &&
          editable &&
          (confirmOrCancel &&
          confirmOrCancel?.state.status !== EditModalStatus.CLOSED ? (
            <ConfirmOrCancel
              wrapped
              state={confirmOrCancel.state}
              onCancel={confirmOrCancel.onCancel}
              onConfirm={confirmOrCancel.onConfirm}
            />
          ) : (
            <ButtonCard onClick={onEdit}>
              <IconButton
                type={IconType.EDIT}
                size={IconButtonSize.SMALL}
                color={theme.colors.blue[500]}
              />
            </ButtonCard>
          ))}
      </Row>
      {rows.map((row, index) => (
        <Row key={row.title + index}>
          <Title>{row.title}</Title>
          <Content>{row.content || emptyCell}</Content>
        </Row>
      ))}
    </Column>
  );
};
