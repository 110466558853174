import { Icon, IconType } from "components/icon";
import React from "react";
import styled from "styled-components";

interface Props {
  type: IconType;
  onClick?: () => void;
  size?: IconButtonSize;
  color?: string;
  background?: string;
}

export function IconButton(props: Props): JSX.Element {
  const {
    type,
    onClick,
    size = IconButtonSize.NORMAL,
    color,
    background = "rgba(54, 25, 25, 0)",
  } = props;
  return (
    <ClickableArea onClick={onClick} size={size} background={background}>
      <Icon type={type} color={color} />
    </ClickableArea>
  );
}

const ClickableArea = styled.div<{ size?: IconButtonSize; background: string }>`
  height: ${({ size }) => (size === IconButtonSize.SMALL ? "24px" : "48px")};
  width: ${({ size }) => (size === IconButtonSize.SMALL ? "24px" : "48px")};

  height: ${({ size }) => (size === IconButtonSize.LARGE ? "60px" : "24px")};
  width: ${({ size }) => (size === IconButtonSize.LARGE ? "60px" : "24px")};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};
  border-radius: ${({ theme }): string => theme.borderRadius.small};
  background: ${({ background }): string => background};
`;

export enum IconButtonSize {
  SMALL = "SMALL",
  NORMAL = "NORMAL",
  LARGE = "LARGE",
}
