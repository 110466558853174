import { IconButton, IconButtonSize, IconType } from "components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../theme";

interface Props {
  disable: boolean;
  placeholder: string;
  searchTerm: string;
  updateSearchTerm: (query: { query: string } | undefined) => void;
}

export function SearchInput({
  disable,
  placeholder,
  searchTerm,
  updateSearchTerm,
}: Props): JSX.Element {
  const [term, setTerm] = useState<string | undefined>(searchTerm);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateSearchTerm(term ? { query: term } : undefined);
    }
  };

  useEffect(() => {
    setTerm(searchTerm);
  }, [searchTerm]);

  return (
    <SearchContainer>
      <Input
        type="text"
        placeholder={placeholder}
        value={term}
        onKeyDown={handleKeyPress}
        autoFocus={!disable}
        onChange={(event): void => setTerm(event.currentTarget.value)}
      />
      <IconButton
        size={IconButtonSize.LARGE}
        type={IconType.MAGNIFIER}
        color={"white"}
        background={!disable ? theme.colors.blue[500] : theme.colors.gray[500]}
        onClick={() => updateSearchTerm(term ? { query: term } : undefined)}
      />
    </SearchContainer>
  );
}

const Input = styled.input`
  width: 100%;
  background: transparent;
  padding-left: 20px;
  color: black;
  border: 0;
  font-size: 16px;
  height: 60px;
  margin-right: 15px;
  font-weight: 600;

  background: ${({ theme }): string => theme.colors.gray[500]};

  border-radius: ${({ theme }): string => theme.borderRadius.small};

  &:focus {
    outline-color: ${({ theme }): string => theme.colors.blue[500]};
  }

  &:placeholder {
    color: black;
  }
`;

const SearchContainer = styled.div`
  weight: 500px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;
