export const userIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 3.42857C10.5 5.32212 8.933 6.85714 7 6.85714C5.067 6.85714 3.5 5.32212 3.5 3.42857C3.5 1.53502 5.067 0 7 0C8.933 0 10.5 1.53502 10.5 3.42857Z"
        fill={color ?? "black"}
      />
      <path
        d="M14 16C14 14.1814 13.2625 12.4372 11.9497 11.1513C10.637 9.8653 8.85652 9.14286 7 9.14286C5.14349 9.14286 3.36301 9.8653 2.05025 11.1513C0.737498 12.4372 2.80326e-07 14.1814 0 16H14Z"
        fill={color ?? "black"}
      />
    </svg>
  );
};
