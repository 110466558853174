import { ActivationStatus } from "generated/graphql/graphql";
import { DateTime } from "luxon";

export function setIsOnlineStatus(
  shippedAt: DateTime | undefined,
  lastSeenAt: DateTime | undefined,
  lastMessageAt: DateTime | undefined,
  status: ActivationStatus | undefined
): string {
  if (
    lastSeenAt == null &&
    lastMessageAt == null &&
    status === "ACTIVATED" &&
    shippedAt &&
    shippedAt < DateTime.fromISO("2022-05-05T00:00:00.00")
  ) {
    return "> 1 år";
  } else return "Ikke sett";
}
