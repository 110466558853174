import { PostcodeCountry } from "generated/graphql/graphql";

export enum TranslatedCountry {
  NORGE = "Norge",
  UNKNOWN = "—",
}

export const getTranslatedCountry = (
  country: PostcodeCountry | undefined | null
): TranslatedCountry => {
  switch (country) {
    case PostcodeCountry.Norway:
      return TranslatedCountry.NORGE;
    default:
      return TranslatedCountry.UNKNOWN;
  }
};

export const getPostCodeCountry = (
  country: TranslatedCountry | undefined
): PostcodeCountry | undefined => {
  switch (country) {
    case TranslatedCountry.NORGE:
      return PostcodeCountry.Norway;
    default:
      return undefined;
  }
};
