import { PropsWithChildren } from "react";
import styled from "styled-components";

interface ToolTipContainerProps {
  color?: string;
  backgroundColor?: string;
}

interface ToolTipProps extends ToolTipContainerProps {
  text: string;
}

export function ToolTip(props: PropsWithChildren<ToolTipProps>): JSX.Element {
  return (
    <ToolTipContainer
      backgroundColor={props.backgroundColor}
      color={props.color}
    >
      {props.children}
      <span className="tooltiptext">{props.text}</span>
    </ToolTipContainer>
  );
}

const ToolTipContainer = styled.div<ToolTipContainerProps>`
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: ${(props) => props.backgroundColor ?? "#fff"};
    color: ${(props) => props.color ?? "#000"};
    text-align: center;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 100;
    bottom: 0px;
    right: 0px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  :hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;
