export const menuIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 10H0" stroke={color ?? "#222222"} strokeWidth="4" />
      <path d="M24 2H0" stroke={color ?? "#222222"} strokeWidth="4" />
    </svg>
  );
};
