import { useAuth } from "app/auth";
import { Routes } from "app/routes";
import {
  Button,
  ButtonVariant,
  Column,
  Input,
  Loader,
  Message,
  MessageType,
  NarrowScreen,
} from "components";
import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthHeader } from "./AuthHeader";
import { RegisterStatus } from "./AuthState";
import { VerifyEmail } from "./VerifyEmail";

const MIN_PASSWORD_LENGTH = 6;

export function Register(): JSX.Element {
  const {
    emailInput: email,
    setEmailInput: setEmail,
    register,
    resendVerificationEmail,
    isResendingVerificationEmail,
    registerState,
  } = useAuth();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);

  const registerWithEmailAndPassword = async ({
    event,
    email,
    password,
    passwordConfirmation,
  }: {
    event: FormEvent;
    email: string;
    password: string;
    passwordConfirmation: string;
  }): Promise<void> => {
    event.preventDefault();
    if (password.length < MIN_PASSWORD_LENGTH) {
      return setValidationError(
        `Password should have at least ${MIN_PASSWORD_LENGTH} characters`
      );
    }
    if (password !== passwordConfirmation) {
      return setValidationError("Passwords are not equal");
    }
    setValidationError(null);
    await register({ email, password });
  };

  return (
    <NarrowScreen>
      <AuthHeader />
      {registerState.status === RegisterStatus.REGISTERING ? (
        <Loader />
      ) : registerState.status ===
        RegisterStatus.VERIFICATION_MESSAGE_VISIBLE ? (
        <Column>
          <VerifyEmail
            resendVerificationEmail={resendVerificationEmail}
            isResendingVerificationEmail={isResendingVerificationEmail}
            onEmailVerifiedManuallyClicked={(): void =>
              navigate(Routes.SIGN_IN)
            }
          />
        </Column>
      ) : (
        <Form
          onSubmit={(event: FormEvent<HTMLElement>): void => {
            registerWithEmailAndPassword({
              email,
              event,
              password,
              passwordConfirmation,
            });
          }}
        >
          <Column>
            <Input
              label="Email"
              type="email"
              id="email"
              autoComplete="username"
              value={email}
              onChange={(event: FormEvent<HTMLInputElement>): void =>
                setEmail(event.currentTarget.value)
              }
              required
            />
            <Input
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(event: FormEvent<HTMLInputElement>): void =>
                setPassword(event.currentTarget.value)
              }
              autoComplete="new-password"
              required
            />
            <Input
              label="Confirm password"
              type="password"
              id="confirm-password"
              value={passwordConfirmation}
              onChange={(event: FormEvent<HTMLInputElement>): void =>
                setPasswordConfirmation(event.currentTarget.value)
              }
              autoComplete="new-password"
              required
            />
            {validationError != null && (
              <Message type={MessageType.ERROR}>{validationError}</Message>
            )}
            {registerState.status === RegisterStatus.FAILED && (
              <Message type={MessageType.ERROR}>
                {registerState.error.message}
              </Message>
            )}
            <Button text="Register" type="submit" />
            <Button
              type="button"
              variant={ButtonVariant.SECONDARY}
              text="Already registered?"
              onClick={(): void => {
                navigate(Routes.SIGN_IN);
              }}
            />
          </Column>
        </Form>
      )}
    </NarrowScreen>
  );
}

const Form = styled.form`
  width: 100%;
`;
