export enum MeterProducer {
  AIDON = "Aidon",
  EMBRIQ = "Embriq",
  KAIFA = "Kaifa",
  KAMSTRUP = "Kamstrup",
  UNKNOWN = "Ukjent",
  NEVER_SEEN = "—",
}

export const getMeterProducer = (
  meterId: string | undefined
): MeterProducer => {
  // eslint-disable-next-line no-magic-numbers
  const hasTwelveCharacters = meterId?.length === 16;
  if (hasTwelveCharacters) {
    if (meterId?.startsWith("7359")) {
      // This will also include some Embriq meters
      return MeterProducer.AIDON;
    } else if (meterId?.startsWith("697063140")) {
      return MeterProducer.KAIFA;
    } else if (meterId?.startsWith("5706567")) {
      return MeterProducer.KAMSTRUP;
    } else {
      return MeterProducer.EMBRIQ;
    }
  } else if (meterId) {
    return MeterProducer.UNKNOWN;
  } else {
    return MeterProducer.NEVER_SEEN;
  }
};
