import React, { PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
  type?: MessageType;
  title?: string;
}

export function Message(props: PropsWithChildren<Props>): JSX.Element | null {
  const { children, type, title } = props;

  if (children == null) {
    return null;
  }
  switch (type) {
    case MessageType.ERROR:
      return <Error>{children}</Error>;
    default:
      return (
        <Info>
          {title == null ? null : <Title>{title}</Title>}
          <p>{children}</p>
        </Info>
      );
  }
}

const Title = styled.h3`
  margin-bottom: 1rem;
  font-weight: bold;
`;

const Error = styled.div`
  background-color: ${({ theme }): string => theme.colors.danger[500]};
  border-radius: ${({ theme }): string => theme.borderRadius.normal};
  padding: ${({ theme }): string => theme.spacing.small};
`;

const Info = styled.div`
  color: black;
  background-color: transparent;
`;

export enum MessageType {
  ERROR = "ERROR",
  INFO = "INFO",
}
