import React from "react";
import styled from "styled-components";

interface Props {
  text: string;
  onClick?: () => void;
}

export function ButtonAll(props: Props): JSX.Element {
  const { text, onClick } = props;

  return (
    <ButtonContainer onClick={onClick}>
      <Title>{text}</Title>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;  

  margin-top: 20px;
  margin-bottom: 60px;
  
  box-shadow: 0 3px 7px 5px rgba(0, 0, 0, 0.20);

  border-radius: 32px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};
  background: ${({ theme }): string => theme.colors.white[500]};
  border: 0;
  color: ${({ theme }): string => theme.colors.blue[500]};
  
  font-size: ${({ theme }): string => theme.fontSizes.large}};
  text-align: center;
  text-transform: uppercase;
  white-space: pre-line;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }  
`;

const Title = styled.h2`
    font-size: ${({ theme }): string => theme.fontSizes.small}};
    text-transform: uppercase;
    margin: 4px 0;
`;
