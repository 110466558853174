import { PropsWithChildren, useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "../../hooks";
import { IconButton } from "../button";
import { IconType } from "../icon";

const Wrapper = styled.div<{
  open: boolean;
}>`
  position: fixed;
  display: ${(props) => (props.open ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  z-index: 1;

  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
`;

const ModalWrapper = styled.div`
  position: relative;

  background-color: ${(props) => props.theme.colors.background[500]};
  color: black;

  @media (min-width: 480px) {
    height: clamp(200px, auto, 60vh);
    width: clamp(60vw, 70vw, 80vw);
  }

  @media (max-width: 480px) {
    height: 100%;
    width: 100%;

    overflow: scroll;
  }
`;

const Header = styled.div`
  width: 100%;
  height: auto;

  padding: 2rem;
  background-color: ${(props) => props.theme.colors.gray[500]};
  color: black !important;
`;

const ModalContent = styled.div`
  padding: 2rem;
  color: black;
`;

const Close = styled.div`
  position: absolute;
  right: 1rem;
  top: 1.7rem;
`;

export interface ModalProps {
  open: boolean;
  header?: JSX.Element;
  onClose: () => void;
}

export const Modal = ({
  open,
  header,
  onClose,
  children,
}: PropsWithChildren<ModalProps>): JSX.Element => {
  const ref = useRef(null);

  useOnClickOutside(ref, onClose);

  window.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      onClose();
    }
  });

  return (
    <Wrapper open={open}>
      <ModalWrapper ref={ref}>
        {header && <Header>{header}</Header>}
        <ModalContent>{children}</ModalContent>
        <Close>
          <IconButton type={IconType.CLOSE} color={"black"} onClick={onClose} />
        </Close>
      </ModalWrapper>
    </Wrapper>
  );
};
