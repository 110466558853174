export const closeIcon = (color?: string): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14.0661L1.87068 15.9368L7.96863 9.83888L14.1298 16L16.0004 14.1293L9.83931 7.9682L15.9368 1.87068L14.0661 0L7.96863 6.09752L1.93429 0.0631828L0.0636054 1.93386L6.09795 7.9682L0 14.0661Z"
        fill={color ?? "#FFA0B7"}
      />
    </svg>
  );
};
