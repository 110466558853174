import { Loader, LoaderSize } from "components";
import React from "react";
import styled from "styled-components";

interface Props {
  text: string;
  type?: "submit" | "reset" | "button";
  variant?: ButtonVariant;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

export function Button(props: Props): JSX.Element {
  const {
    type,
    text,
    variant,
    onClick,
    disabled = false,
    loading = false,
  } = props;
  switch (variant) {
    case ButtonVariant.LINK:
      return (
        <LinkButton
          type={type}
          onClick={onClick}
          disabled={disabled ?? loading}
        >
          {loading ? <Loader size={LoaderSize.SMALL} /> : text}
        </LinkButton>
      );
    case ButtonVariant.INLINE:
      return (
        <InlineButton
          type={type}
          onClick={onClick}
          disabled={disabled ?? loading}
        >
          {loading ? <Loader size={LoaderSize.SMALL} /> : text}
        </InlineButton>
      );
    case ButtonVariant.SECONDARY:
      return (
        <SecondaryButton
          type={type}
          onClick={onClick}
          disabled={disabled ?? loading}
        >
          {loading ? <Loader size={LoaderSize.SMALL} /> : text}
        </SecondaryButton>
      );
    case ButtonVariant.PRIMARY:
    default:
      return (
        <PrimaryButton
          type={type}
          onClick={onClick}
          disabled={disabled ?? loading}
        >
          {loading ? <Loader size={LoaderSize.SMALL} /> : text}
        </PrimaryButton>
      );
  }
}

const PrimaryButton = styled.button`
  position: relative;
  display: block;

  font-size: ${({ theme }): string => theme.fontSizes.large};
  font-weight: bold;
  width: 100%;
  padding: 9px 24px;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  color: ${({ theme }): string => theme.colors.white[500]};
  letter-spacing: ${({ theme }): string => theme.letterSpacing.large};
  background: ${({ theme }): string => theme.colors.blue[500]};
  border-radius: ${({ theme }): string => theme.borderRadius.small};

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

const SecondaryButton = styled.button`
  position: relative;
  display: block;

  font-size: ${({ theme }): string => theme.fontSizes.large};
  font-weight: bold;
  width: 100%;
  padding: 9px 24px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: ${({ theme }): string => theme.colors.blue[500]};
  letter-spacing: ${({ theme }): string => theme.letterSpacing.large};
  border-width: 2px;
  border-radius: ${({ theme }): string => theme.borderRadius.small};
  border-style: solid;
  border-color: ${({ theme }): string => theme.colors.blue[500]};
  background: ${({ theme }): string => theme.colors.white[500]};

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const InlineButton = styled.button`
  border: none;
  font-weight: bold;
  color: ${({ theme }): string => theme.colors.text};
  font-size: ${({ theme }): string => theme.fontSizes.small};
  text-transform: uppercase;
  padding: 7px 0;
  background: transparent;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

const LinkButton = styled.button`
  position: relative;
  display: block;

  font-size: ${({ theme }): string => theme.fontSizes.large};
  font-weight: bold;
  width: 100%;
  padding: 9px 24px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  background: transparent;
  border: 0;
  color: ${({ theme }): string => theme.colors.text};

  text-decoration: underline;
  letter-spacing: ${({ theme }): string => theme.letterSpacing.large};

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    outline: none;
  }
`;

export enum ButtonVariant {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  LINK = "LINK",
  INLINE = "INLINE",
}
