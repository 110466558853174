import styled from "styled-components";
import { theme } from "../theme";
import { IconButton, IconButtonSize } from "./button";
import { IconType } from "./icon";
import { Loader, LoaderSize } from "./Loader";
import { ToolTip } from "./tooltip";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 4px;
`;

const StyledButton = styled.div<{ wrapped?: boolean }>`
  ${(props) =>
    props.wrapped &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  
    width: 100%;
    height: 100%;

    border-radius: 4px;
    background: ${theme.colors.gray[500]};
    
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25),
      inset 1px 1px 1px rgba(255, 255, 255, 0.25);
  `}
`;

export interface ConfirmOrCancelProps {
  state: EditModalState;
  wrapped?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmOrCancel({
  state,
  wrapped,
  onConfirm,
  onCancel,
}: ConfirmOrCancelProps): JSX.Element | null {
  return state.status === EditModalStatus.CLOSED ? null : state.status ===
    EditModalStatus.FAILED ? (
    <IconWrapper>
      <StyledButton wrapped={wrapped}>
        <ToolTip
          text={state.error}
          backgroundColor={theme.colors.danger[500]}
          color="white"
        >
          <IconButton
            color={theme.colors.danger[500]}
            type={IconType.EXCLAMATION}
            size={IconButtonSize.SMALL}
          />
        </ToolTip>
      </StyledButton>
      <StyledButton wrapped={wrapped}>
        <IconButton
          type={IconType.REFRESH}
          size={IconButtonSize.SMALL}
          onClick={onConfirm}
        />
      </StyledButton>
    </IconWrapper>
  ) : (
    <IconWrapper>
      {state.status === EditModalStatus.SAVING ? (
        <Loader size={LoaderSize.SMALL} />
      ) : (
        <>
          <StyledButton wrapped={wrapped}>
            <IconButton
              type={IconType.CHECK}
              color={theme.colors.success[500]}
              size={IconButtonSize.SMALL}
              onClick={() => onConfirm()}
            />
          </StyledButton>
          <StyledButton wrapped={wrapped}>
            <IconButton
              type={IconType.CLOSE}
              color={theme.colors.danger[500]}
              size={IconButtonSize.SMALL}
              onClick={onCancel}
            />
          </StyledButton>
        </>
      )}
    </IconWrapper>
  );
}

export enum EditModalStatus {
  CLOSED,
  OPEN,
  SAVING,
  FAILED,
}

interface ClosedModal {
  status: EditModalStatus.CLOSED;
}

interface OpenModal {
  status: EditModalStatus.OPEN;
}

interface SavingModal {
  status: EditModalStatus.SAVING;
}

interface FailedModal {
  status: EditModalStatus.FAILED;
  error: string;
}

export type EditModalState =
  | ClosedModal
  | OpenModal
  | SavingModal
  | FailedModal;
